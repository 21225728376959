import React, { ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Form, Input } from 'antd';

import { getValidationRules, ValidationRuleType } from './validation';

const { TextArea } = Input;

interface SimpleTextareaInputProps<T> extends UseControllerProps<T> {
  errors: FieldErrors;
  label: string;
  placeholder?: string;
  validations?: ValidationRuleType[];
  rows?: number;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const SimpleTextareaInput = <T extends FieldValues>({
  control,
  errors,
  name,
  label,
  placeholder,
  validations,
  rows = 4,
  ...otherProps
}: SimpleTextareaInputProps<T>): ReactElement => {
  const validationRules = getValidationRules(validations);

  return (
    <Form.Item label={label} validateStatus={errors[name]?.message ? 'error' : ''}>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => {
          const { value: defaultValue, ...rest } = { ...field };
          return (
            <TextArea
              rows={rows}
              placeholder={placeholder}
              {...rest}
              {...otherProps}
              onChange={e => field.onChange(e)}
            >
              {defaultValue}
            </TextArea>
          );
        }}
      />
      {errors[name]?.message}
    </Form.Item>
  );
};
