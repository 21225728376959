import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NoAuth } from 'layouts';
import { Error404 } from 'pages/errors/Error404';
import { RouteType } from 'routes';

interface RendererType {
  isAuthenticated: boolean;
  routeItems: RouteType[];
}

export const PublicRenderer: React.FC<RendererType> = ({ isAuthenticated, routeItems }) => {
  if (isAuthenticated) {
    window.location.href = '/dashboard';
    return <></>;
  }

  return (
    <NoAuth>
      <Switch>
        {routeItems.map((routeItem: RouteType, key: number) => {
          const { exact, path, Component } = routeItem;
          return (
            <Route
              key={`non-auth-route-${key}`}
              exact={exact}
              path={path as string}
              render={() => <Suspense fallback={null}>{Component && <Component />}</Suspense>}
            />
          );
        })}
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="*" exact render={() => <Error404 isAuthenticated={isAuthenticated} />} />
      </Switch>
    </NoAuth>
  );
};
