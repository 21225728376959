import React, { useState } from 'react';
import { Layout } from 'antd';

import { Menu } from './Menu';

interface SideLayoutProps {
  isCollapsed: boolean;
}

export const SideLayout: React.FC<SideLayoutProps> = ({ isCollapsed }) => {
  const [menuMode, setMenuMode] = useState<'vertical' | 'inline'>('inline');

  return (
    <Layout.Sider
      theme="dark"
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      breakpoint="md"
      onBreakpoint={breakpoint => setMenuMode(breakpoint ? 'vertical' : 'inline')}
      width={240}
    >
      <Menu isCollapsed={isCollapsed} menuMode={menuMode} />
    </Layout.Sider>
  );
};
