import React, { useState } from 'react';
import { Layout } from 'antd';

import { SideLayout } from '../components/sidePanel/SideLayout';
import { AppContext } from '../context';

export const Main: React.FC = ({ children }) => {
  const [isCollapsed, updateCollapse] = useState(false);

  return (
    <AppContext.Provider
      value={{
        menuCollapsed: isCollapsed,
        setMenuCollapsed: updateCollapse,
      }}
    >
      <Layout>
        {(window.innerWidth >= 768 || !isCollapsed) && (
          <SideLayout isCollapsed={window.innerWidth < 768 || isCollapsed} />
        )}
        {children}
      </Layout>
    </AppContext.Provider>
  );
};
