import React, { ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Form, Input, Typography } from 'antd';

import { getValidationRules, ValidationRuleType } from './validation';

interface PasswordInputProps<T> extends UseControllerProps<T> {
  errors: FieldErrors;
  label: string;
  placeholder?: string;
  validations?: ValidationRuleType[];
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const { Text } = Typography;

export const PasswordInput = <T extends FieldValues>({
  control,
  errors,
  name,
  label,
  placeholder,
  validations,
  ...otherProps
}: PasswordInputProps<T>): ReactElement => {
  const validationRules = getValidationRules(validations);
  const { onChange } = { ...otherProps };

  return (
    <Form.Item label={label} validateStatus={errors[name]?.message ? 'error' : ''}>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => {
          const { name, ...rest } = { ...field };
          return (
            <Input.Password
              placeholder={placeholder}
              name={name}
              {...rest}
              {...otherProps}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
          );
        }}
      />
      {errors[name]?.message && <Text type="danger">{errors[name]?.message}</Text>}
    </Form.Item>
  );
};
