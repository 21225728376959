import React, { ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Form, Row, Switch, Typography } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

import { getValidationRules, ValidationRuleType } from './validation';

interface SwitchInputProps<T> extends UseControllerProps<T> {
  errors: FieldErrors;
  label?: string;
  labelAlign?: FormLabelAlign;
  placeholder?: string;
  validations?: ValidationRuleType[];
  onChange?: SwitchChangeEventHandler;
  [key: string]: unknown;
}

const { Text } = Typography;

export const SwitchInput = <T extends FieldValues>({
  control,
  errors,
  name,
  label,
  labelAlign = 'right',
  validations,
  ...otherProps
}: SwitchInputProps<T>): ReactElement => {
  const validationRules = getValidationRules(validations);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => {
          // We need to take value out from rest since it will thow an error on <Switch />
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { value, ...rest } = field;

          return (
            <Row>
              {label && labelAlign === 'left' && (
                <Form.Item
                  className="form-item--label"
                  label={label}
                  labelAlign={labelAlign}
                  validateStatus={errors[name]?.message ? 'error' : ''}
                  colon={false}
                />
              )}

              <Switch
                checked={field.value}
                className={labelAlign === 'left' ? 'switch-left-label' : 'switch-right-label'}
                {...rest}
                {...otherProps}
                onChange={(checked, e) => {
                  if (typeof otherProps.onChange !== 'undefined') {
                    otherProps.onChange(checked, e);
                    field.onChange(checked, e);
                  }
                }}
              />
              {label && labelAlign === 'right' && (
                <Form.Item
                  className="form-item--label"
                  label={label}
                  labelAlign={labelAlign}
                  validateStatus={errors[name]?.message ? 'error' : ''}
                  colon={false}
                />
              )}
            </Row>
          );
        }}
      />
      {errors[name]?.message && <Text type="danger">{errors[name]?.message}</Text>}
    </>
  );
};
