import { getBase } from './getBase';

export const setLoginToken = async (token: string): Promise<void> => {
  localStorage.setItem('booston-centralized-token', token);

  await getBase({
    resource: 'v1/admin/bull-board-auth/validate-token',
    method: 'get',
    base: 'centralized',
    options: {
      withCredentials: true,
    },
  });
};

export const removeLoginToken = async (forceLogout = false): Promise<void> => {
  if (forceLogout) {
    localStorage.removeItem('booston-centralized-token');
    return;
  }

  try {
    await getBase({
      resource: 'v1/admin/bull-board-auth/remove-token',
      method: 'get',
      base: 'centralized',
      options: {
        withCredentials: true,
      },
    });
    localStorage.removeItem('booston-centralized-token');
  } catch (err) {
    localStorage.removeItem('booston-centralized-token');
  }
};
