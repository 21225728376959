import React, { ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { DatePicker, Form, Typography } from 'antd';

import { getValidationRules, ValidationRuleType } from './validation';

interface DatePickerInputProps<T> extends UseControllerProps<T> {
  errors: FieldErrors;
  label: string;
  placeholder?: string;
  validations?: ValidationRuleType[];
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

const { Text } = Typography;

export const DatePickerInput = <T extends FieldValues>({
  control,
  errors,
  name,
  label,
  placeholder,
  validations,
  ...otherProps
}: DatePickerInputProps<T>): ReactElement => {
  const validationRules = getValidationRules(validations);

  return (
    <Form.Item
      label={label}
      validateStatus={errors[name]?.message ? 'error' : ''}
      style={{ width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => {
          return (
            <DatePicker
              placeholder={placeholder}
              {...field}
              {...otherProps}
              onChange={date => field.onChange(date)}
            />
          );
        }}
      />
      {errors[name]?.message && <Text type="danger">{errors[name]?.message}</Text>}
    </Form.Item>
  );
};
