import React, { ReactElement } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { Select, SelectProps } from 'antd';

interface NativeSelectInterface<T> {
  field?: ControllerRenderProps<T, Path<T>>;
  placeholder?: string;
  options: { value: string | number; label: string }[];
  defaultValue?: string | number;
  value?: string | number;
  mode?: SelectProps<T>['mode'];
  onSearch?(value: string): void;
  notFoundContent?: JSX.Element | null;
  open?: boolean;
  onChange?(e: unknown): void;
}

export const NativeSelect = <T extends FieldValues>(
  props: NativeSelectInterface<T>,
): ReactElement => {
  return (
    <Select
      open={props.open}
      mode={props.mode}
      defaultValue={props.defaultValue}
      value={props.value}
      style={{ width: '100%' }}
      showSearch={true}
      placeholder={props.placeholder}
      {...props.field}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
      }
      showArrow={false}
      defaultActiveFirstOption={false}
      onSearch={props.onSearch}
      notFoundContent={props.notFoundContent}
      {...(props.onChange ? { onChange: props.onChange } : {})}
    >
      {props.options?.map((option, index) => (
        <Select.Option key={`select-${name}-option-${index}`} value={option?.value}>
          {option?.label}
        </Select.Option>
      ))}
    </Select>
  );
};
