import { Leafs } from 'types';

export const COLORS = {
  sorbus: {
    '10': '#FFF4EC',
    '50': '#FFDEC3',
    '100': '#FED3AF',
    '200': '#FEBC86',
    '300': '#FEA65E',
    '400': '#FD8F35',
    '500': '#FD790D', // primary-color
    '600': '#D05F02',
    '700': '#994501',
    '800': '#612C01',
    '900': '#291300',
  },
  sanJuan: {
    '10': '#C9D7E4',
    '50': '#93AEC9',
    '100': '#86A4C2',
    '200': '#6A8FB4', // Accent color
    '300': '#527BA3',
    '400': '#456688',
    '500': '#37526D', // secondary-color
    '600': '#2a3f54',
    '700': '#172d44',
    '800': '#15293d',
    '900': '#101f30',
  },
  malachite: {
    '10': '#E8FDE9',
    '50': '#C7FACE',
    '100': '#7DF28C',
    '200': '#58EE6B',
    '300': '#33EA4A',
    '400': '#17DE2F',
    '500': '#13B927',
    '600': '#0E861C',
    '700': '#095312',
    '800': '#032007',
    '900': '#021a06',
  },
  pomegranate: {
    '10': '#FEEDEC',
    '50': '#FCC8C5',
    '100': '#FBB6B1',
    '200': '#F9928A',
    '300': '#F76E64',
    '400': '#F5493D',
    '500': '#F32516',
    '600': '#C7170A',
    '700': '#911107',
    '800': '#5C0B05',
    '900': '#270402',
  },
  turbo: {
    '10': '#FFFBD7',
    '50': '#FFF7AE',
    '100': '#FFF599',
    '200': '#FFF070',
    '300': '#FFEC48',
    '400': '#FFE81F',
    '500': '#F5DC00', // Warning
    '600': '#BDAA00',
    '700': '#857700',
    '800': '#4D4500',
    '900': '#151200',
  },
  nobel: {
    '10': '#fbfbfb',
    '50': '#f8f8f8',
    '100': '#f7f7f7',
    '200': '#F6F6F6', // Whiteish
    '300': '#E2E1E1',
    '400': '#CECDCD',
    '500': '#BAB8B8', // Neutral
    '600': '#9E9C9C',
    '700': '#837F7F',
    '800': '#666363',
    '900': '#4A4848',
  },
} as const;

export type colorType = Leafs<typeof COLORS>;
