import React, { Component, ErrorInfo, ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo: null | Error;
}

export class ErrorWrapper extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorInfo: null,
  };

  public componentDidMount(): void {
    const { REACT_APP_BUGSNAG_KEY } = process.env;
    if (REACT_APP_BUGSNAG_KEY) {
      Bugsnag.start({
        apiKey: REACT_APP_BUGSNAG_KEY || '',
      });
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Bugsnag.notify(error);
    this.setState({ errorInfo: error });
    // eslint-disable-next-line no-console
    console.error('Uncaught error in error wrapper:', error, errorInfo);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public render() {
    if (this.state.hasError) {
      return <h1>Sorry... There was an error</h1>;
    }

    return this.props.children;
  }
}
