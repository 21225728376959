import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Main } from 'layouts';
import { Error404 } from 'pages/errors/Error404';
import { RouteType } from 'routes';

import { removeLoginToken } from '../../utils/auth';

interface RendererType {
  isAuthenticated?: boolean;
  routeItems: RouteType[];
  allowedRoutes?: RouteType[];
}

export const PrivateRenderer: React.FC<RendererType> = ({ isAuthenticated, routeItems }) => {
  if (!isAuthenticated) {
    removeLoginToken().then(() => {
      window.location.href = '/login';
    });
    return <></>;
  }

  return (
    <Main>
      <Switch>
        {routeItems.map((routeItem: RouteType, key: number) => {
          const { exact, path, Component } = routeItem;
          return (
            <Route
              key={`auth-route-${key}`}
              exact={exact}
              path={path as string}
              render={() => <Suspense fallback={null}>{Component && <Component />}</Suspense>}
            />
          );
        })}
        <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
        <Route path="*" exact render={() => <Error404 isAuthenticated={isAuthenticated} />} />
      </Switch>
    </Main>
  );
};
