import React, { ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Form, Typography } from 'antd';

import { NativeSelect } from './NativeSelect';
import { getValidationRules, ValidationRuleType } from './validation';

export interface SelectOptionsProps {
  value: string | number;
  label: string;
}

interface SelectInputProps<T> extends UseControllerProps<T> {
  errors: FieldErrors;
  label: string;
  placeholder?: string;
  validations?: ValidationRuleType[];
  options: SelectOptionsProps[];
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  value?: string | number;
  mode?: 'multiple' | 'tags';
}

const { Text } = Typography;

export const SelectInput = <T extends FieldValues>({
  control,
  errors,
  name,
  label,
  placeholder,
  validations,
  options,
  value,
  defaultValue,
  mode,
}: SelectInputProps<T>): ReactElement => {
  const validationRules = getValidationRules(validations);
  return (
    <Form.Item label={label} validateStatus={errors[name]?.message ? 'error' : ''}>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <NativeSelect
            value={value}
            defaultValue={defaultValue}
            field={field}
            placeholder={placeholder}
            options={options}
            mode={mode}
          />
        )}
      />
      {errors[name]?.message && <Text type="danger">{errors[name]?.message}</Text>}
    </Form.Item>
  );
};
