import React from 'react';
import {
  faDatabase,
  faHouseChimney,
  faList,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MenuIconProps {
  slug: string;
}

export const MenuIcon: React.FC<MenuIconProps> = ({ slug }) => {
  switch (slug.toLocaleLowerCase()) {
    case 'home':
      return <FontAwesomeIcon icon={faHouseChimney} style={{ paddingRight: 8 }} />;
    case 'queues':
      return <FontAwesomeIcon icon={faDatabase} style={{ paddingRight: 8 }} />;
    case 'clients':
      return <FontAwesomeIcon icon={faList} style={{ paddingRight: 8 }} />;
    case 'leads':
      return <FontAwesomeIcon icon={faPeopleGroup} style={{ paddingRight: 8 }} />;
  }

  return <></>;
};
