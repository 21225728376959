import React from 'react';
import { Layout } from 'antd';

// eslint-disable-next-line @typescript-eslint/ban-types
export const NoAuth: React.FC<{}> = ({ children }) => {
  return (
    <Layout className="non-auth-layout" style={{ height: '100%' }}>
      {children}
    </Layout>
  );
};
