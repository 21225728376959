import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Space } from 'antd';

interface Error {
  isAuthenticated: boolean;
}

export const Error404: React.FC<Error> = ({ isAuthenticated }) => {
  return (
    <Space size="large" direction="vertical" style={{ width: '100%', height: '100%' }}>
      <Helmet title="Error 404" />

      <Row>
        <Col span={12} className="login-card">
          <div className="card card-shadow">
            <Card title="Error 404" bordered={false} className="no-background">
              <p>Error 404 - Page not found</p>
              {isAuthenticated ? (
                <Link to="/dashboard">Back to Dashboard</Link>
              ) : (
                <Link to="/login">Go to login</Link>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </Space>
  );
};
