import { lazy } from 'react';

export interface RouteType {
  path: null | string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.FC<any> | React.LazyExoticComponent<React.FC<any>> | null;
  exact: boolean;
  auth: boolean;
  key: string | null;
  label: string;
  menuItem: boolean;
  children: RouteType[];
}
export const QueueKey = 'queues';

export const routes: RouteType[] = [
  {
    path: '/login',
    Component: lazy(() =>
      import('pages/auth/Login').then(({ Login }) => ({
        default: Login,
      })),
    ),
    exact: true,
    auth: false,
    key: null,
    label: 'Login',
    menuItem: false,
    children: [],
  },
  {
    path: '/logout',
    Component: lazy(() =>
      import('pages/auth/Logout').then(({ Logout }) => ({
        default: Logout,
      })),
    ),
    exact: true,
    auth: true,
    key: null,
    label: 'Logout',
    menuItem: false,
    children: [],
  },
  {
    path: '/dashboard',
    Component: lazy(() =>
      import('pages/dashboard').then(({ Dashboard }) => ({
        default: Dashboard,
      })),
    ),
    exact: true,
    auth: true,
    key: 'dashboard',
    label: 'Home',
    menuItem: true,
    children: [],
  },
  {
    path: '/queues',
    Component: null,
    exact: true,
    auth: true,
    key: QueueKey,
    label: 'Queues',
    menuItem: true,
    children: [],
  },
  {
    path: '/queues/:name',
    Component: lazy(() =>
      import('pages/queues').then(({ Queues }) => ({
        default: Queues,
      })),
    ),
    exact: true,
    auth: true,
    key: QueueKey,
    label: 'Queues',
    menuItem: false,
    children: [],
  },
  {
    path: '/clients',
    Component: lazy(() =>
      import('pages/clients').then(({ Clients }) => ({
        default: Clients,
      })),
    ),
    exact: true,
    auth: true,
    key: null,
    label: 'Clients',
    menuItem: true,
    children: [],
  },
  {
    path: ['/clients/edit/:id', '/clients/new'],
    Component: lazy(() =>
      import('pages/clients/Edit').then(({ Edit }) => ({
        default: Edit,
      })),
    ),
    exact: true,
    auth: true,
    key: 'clients',
    label: 'Clients',
    menuItem: false,
    children: [],
  },
  {
    path: '/leads',
    Component: lazy(() =>
      import('pages/leads').then(({ Leads }) => ({
        default: Leads,
      })),
    ),
    exact: true,
    auth: true,
    key: null,
    label: 'Leads',
    menuItem: true,
    children: [],
  },
];

export const flattenRoutes = (routes: RouteType[] = []): RouteType[] => {
  if (routes.length === 0) {
    return [];
  }
  const totalRoutes: RouteType[] = [];
  routes.forEach(item => {
    if (item.path) {
      totalRoutes.push({
        ...item,
        children: [],
      });
    }
    if (item.children.length) {
      totalRoutes.push(...flattenRoutes(item.children));
    }
  });

  return totalRoutes;
};
