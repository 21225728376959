import axios, { AxiosRequestConfig, Method } from 'axios';

import {
  FORBIDDEN_ERROR,
  INTERNAL_ERROR,
  NOT_FOUND_ERROR,
  TOO_MANY_REQUEST_ERROR,
} from './constants/errors';
import { removeLoginToken } from './auth';
import { showError } from './Notification';

export interface getBaseProps {
  method: Method;
  resource: string;
  options?: AxiosRequestConfig;
  headers?: Record<string, string>;
  base?: Base_API;
}

export type Base_API = 'centralized';

const ServiceApi = {
  centralized: process.env.REACT_APP_CENTRALIZED_BASE_API,
};

export const getBase = <T,>({
  method = 'GET',
  base = 'centralized',
  resource,
  options,
  headers,
}: getBaseProps): Promise<T> => {
  const site_url = ServiceApi[base];

  return axios({
    method,
    headers: {
      authorization: localStorage.getItem('booston-centralized-token'),
      ...headers,
    },
    url: `${site_url}/${resource}`,
    ...options,
  })
    .then(res => {
      return res?.data;
    })
    .catch(err => {
      if (err.response.status === 301) {
        //@todo - When redirect feature will be in place add a redirect here
        showError(NOT_FOUND_ERROR);
      }
      if (err.response.status === 401) {
        removeLoginToken(true).then(() => {
          window.location.href = '/logout';
        });
      }
      if (err.response.status === 403) {
        showError(FORBIDDEN_ERROR);
        return;
      }
      if (err.response.status === 429) {
        showError(TOO_MANY_REQUEST_ERROR);
        return;
      }
      if (err.response.status >= 500) {
        showError(INTERNAL_ERROR);
      }
      // eslint-disable-next-line no-console
      console.log(err.message);
      throw err;
    });
};

/**
 * Definitely needs some improvement
 * But it's a simple method to have some easy way of making multiple requests and typecasting all of them
 * Ex.
 * getBases<[Record<string, string>, SomeEntity, string]>(getBaseProps, getBaseProps, getBaseProps)
 * @param propsArray
 */
export const getBases = <T,>(propsArray: getBaseProps[]): Promise<T> => {
  const promises: Promise<unknown>[] = [];
  propsArray.forEach(item => {
    promises.push(getBase<unknown>(item));
  });

  // @ts-ignore
  return Promise.all(promises);
};
