import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { notification } from 'antd';
import { IconType } from 'antd/lib/notification/interface';

import { BAD_REQUEST_ERROR } from './constants/errors';

export interface BEError {
  code: string;
  expected: string;
  received: string;
  path: string[];
  message: string;
}

export const showMessage = (message: string): void => {
  showNotification(message, 'success');
};

export const showError = (message: string): void => {
  showNotification(message, 'error');
};

export const showBackendErrors = <T,>(setError: UseFormSetError<T>, errors: BEError[]) => {
  for (const error of errors) {
    const fieldName = error.path[0] ?? '';

    setError(fieldName as never, {
      message: `Value expected to be ${error.expected} but got ${error.received}. Error: ${error.message}.`,
    });
  }
  showNotification(BAD_REQUEST_ERROR, 'error');
};

export const showWarning = (message: string): void => {
  showNotification(message, 'warning');
};

const showNotification = (message: string, type: IconType) => {
  notification.open({
    message: message,
    type: type,
  });
};
