import React, { Dispatch, SetStateAction, useContext } from 'react';

export type AppContextType = {
  menuCollapsed: boolean;
  setMenuCollapsed: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = React.createContext<AppContextType>({
  menuCollapsed: true,
  setMenuCollapsed: () => {
    // Change value
  },
});

export const useApp = (): AppContextType => useContext(AppContext);
