import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ErrorWrapper } from 'components';
import { PrivateRenderer, PublicRenderer } from 'components/auth';

import { flattenRoutes, routes, RouteType } from './routes';

function App() {
  const localToken: string | null = localStorage?.getItem('booston-centralized-token');
  const isAuthenticated = !!localToken;

  const authRoutes: RouteType[] = flattenRoutes(routes).filter(item => item.auth);
  const nonAuthRoutes: RouteType[] = flattenRoutes(routes).filter(item => !item.auth);

  return (
    <ErrorWrapper>
      <Router>
        <Switch>
          {isAuthenticated && (
            <PrivateRenderer
              isAuthenticated={isAuthenticated}
              routeItems={authRoutes}
              allowedRoutes={authRoutes}
            />
          )}

          <PublicRenderer isAuthenticated={isAuthenticated} routeItems={nonAuthRoutes} />
        </Switch>
      </Router>
    </ErrorWrapper>
  );
}

export default App;
