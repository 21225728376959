import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Menu as AntMenu, Row, Tooltip } from 'antd';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { QueueKey, routes, RouteType } from 'routes';
import { getBase } from 'utils';

import { MenuIcon } from './MenuIcon';

interface MenuProps {
  menuMode: 'vertical' | 'inline';
  isCollapsed: boolean;
}

export const logout = () => {
  window.location.href = '/logout';
};

const renderMenuItem = (
  menuData: Pick<RouteType, 'label' | 'path'>,
  key: string | null,
  isCollapsed: boolean,
) => {
  const location = useLocation();
  const { pathname } = location;
  const { path } = menuData;
  const activeClass = pathname === path ? 'ant-menu-item-active' : '';

  return (
    <AntMenu.Item className={activeClass} key={key} icon={<MenuIcon slug={menuData.label} />}>
      <Link to={(menuData.path as string) || ''}>{!isCollapsed ? menuData.label : ''}</Link>
    </AntMenu.Item>
  );
};

export const Menu: React.FC<MenuProps> = ({ isCollapsed, menuMode }) => {
  const [activeOpenedKeys, setActiveDefaultOpened] = useState('');
  const [queues, setQueues] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    getBase<{ queues: { name: string }[] }>({
      resource: 'queue-dashboard/api/queues',
      method: 'get',
      base: 'centralized',
    }).then(res => {
      setQueues(res.queues.map(item => item.name));
    });
  }, []);

  useEffect(() => {
    const activeRoute = routes.find(
      item =>
        item.path === location.pathname ||
        item.children.find(itm => itm.path === location.pathname),
    );
    if (activeRoute) {
      setActiveDefaultOpened(window.innerWidth < 768 ? '' : (activeRoute.key as string));
    }
  }, [location]);

  return (
    <div className="side-layout">
      <div className="side-layout-top">
        <div
          className={classNames({
            'side-layout-identity': true,
            'side-layout-identity--collapsed': isCollapsed,
          })}
        >
          <Link to={'/dashboard'}>
            <div className={'side-layout-identity__logo'} />
          </Link>
        </div>
        <div className="side-layout-menu">
          <AntMenu
            style={{
              maxWidth: '260px',
              paddingTop: isCollapsed ? '' : '2rem',
            }}
            theme="dark"
            mode={menuMode}
            multiple={false}
            inlineIndent={12}
            selectable={false}
            openKeys={[activeOpenedKeys]}
          >
            {routes
              .filter(item => item.menuItem)
              .filter(item => item.auth)
              .map((mItem: RouteType) => {
                if (mItem.key !== QueueKey || queues.length === 0) {
                  return renderMenuItem(mItem as RouteType, mItem.path as string, isCollapsed);
                }
                return (
                  <AntMenu.SubMenu
                    key={mItem.key}
                    icon={<MenuIcon slug={mItem.label} />}
                    title={!isCollapsed ? mItem.label : ''}
                    onTitleClick={() =>
                      setActiveDefaultOpened(activeOpenedKeys !== mItem.key ? `${mItem.key}` : '')
                    }
                  >
                    {queues.map(item =>
                      renderMenuItem({ label: item, path: `${mItem.path}/${item}` }, item, false),
                    )}
                  </AntMenu.SubMenu>
                );
              })}
          </AntMenu>
        </div>
      </div>
      <div className="side-layout-footer">
        <Row style={{ height: '100%' }}>
          <Col style={{ margin: 'auto', display: 'block' }}>
            <Link to="/logout" style={{ justifyContent: 'center' }}>
              <Tooltip title="Logout">
                <FontAwesomeIcon icon={faPowerOff} style={{ fontSize: 24 }} />
              </Tooltip>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};
