/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-useless-escape */

import { Leafs } from 'types';

const validateRules = {
  required: {
    type: 'required',
    rule: true,
    message: 'Field is required',
  },
  salary: {
    type: 'pattern',
    rule: /^\s*-?((\d{1,3}(\.(\d){3})*)|\d*)(,\d{1,2})?\s?(\u20AC)?\s*$/i,
    message: 'Invalid number format, supported format e.g.: 20,000.10',
  },
  phone: {
    type: 'pattern',
    rule: /^[\s()+-]*([0-9][\s()+-]*){10,20}$/,
    message: 'Invalid phone number',
  },
  email: {
    type: 'pattern',
    rule: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi,
    message: 'Email is invalid. e.g.: some@example.com',
  },
  url: {
    type: 'pattern',
    rule: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
    message: 'URL is invalid. e.g.: https://someurl.com',
  },
  domain: {
    type: 'pattern',
    rule: /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi,
    message: 'Domain name incorrect. e.x.: domain.com is a valid value',
  },
  youtubeId: {
    type: 'pattern',
    rule: /^([a-zA-Z0-9_-]{11})?$/gi,
    message: 'YouTube ID is invalid',
  },
  googleTracking: {
    type: 'pattern',
    rule: /(UA|YT|MO)-\d+-\d+/i,
    message: 'Invalid Google tracking code.',
  },
  colorHex: {
    type: 'pattern',
    rule: /(^\[\[([a-zA-Z_]+)\]\]$)|(^\#([0-9a-zA-Z]{3}|[0-9a-zA-Z]{6})$)/gi,
    message: 'Color is invalid. e.g.: #eeff33',
  },
  colorRgb: {
    type: 'pattern',
    rule: /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/gm,
    message: 'Color is invalid. e.g.: rgb(200, 255, 100)',
  },
  colorRgba: {
    type: 'pattern',
    rule: /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/gm,
    message: 'Color is invalid. e.g.: rgb(200, 255, 100, 0.1)',
  },
};

export type ValidationRuleType = keyof typeof validateRules;

export type TypeLeafs<T> = T extends Record<string, { type: Leafs<typeof validateRules> }>
  ? TypeLeafs<T[keyof T]['type']>
  : T;

type ValidationRules = {
  [key: TypeLeafs<typeof validateRules>]: {
    value: boolean | RegExp;
    message: string;
  };
};

export const getValidationRules = (rules: ValidationRuleType[] | undefined): ValidationRules => {
  const validationRules = rules?.map(ruleType => {
    const { rule, message } = validateRules[ruleType];
    return {
      [validateRules[ruleType]['type']]: {
        value: rule,
        message,
      },
    };
  });

  if (Array.isArray(validationRules)) {
    return validationRules.reduce((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});
  }

  return {};
};
